<template>
  <div id="main-wrapper" class="inner-pages login-page">
    <h3>Please wait. Redirecting to your account</h3>
  </div>
</template>
<script>
export default {
  mounted() {
    if ("redirect" in this.$route.query) {
      let params = new URLSearchParams(this.$route.query);
      params = params.toString();
      console.log(`${process.env.VUE_APP_CLIENT_PORTAL_URL}?${params}`);
      window.location.replace(
        `${process.env.VUE_APP_CLIENT_PORTAL_URL}?${params}`
      );
    } else {
      this.$router.push({ name: "Home" });
    }
  },
};
</script>
